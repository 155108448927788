import { MultipleChoiceQuizStep, StepComponentProps } from '../../../@types'
import React, { useState, useMemo, useEffect } from 'react'
import { Set } from 'immutable'
import Actions from '@components/quiz/actions'
import { mapSelectOptions } from '../../SelectOption'
import Icon from '../../Icon'

type Props = StepComponentProps<MultipleChoiceQuizStep, Array<string>>

export default function MultipleChoice(
  props: Props,
): React.ReactElement<Props> {
  const { value, onChange, step } = props
  const [values, setValues] = useState(Set<string>([]))

  const otherFieldPrefix = 'other:'
  const [otherField, setOtherField] = useState('')
  const [prevOtherField, setPrevOtherField] = useState('')
  const [showInput, setShowInput] = useState(false)
  const nextEnabled = useMemo(() => values.size > 0, [values])

  useEffect(() => {
    if (values.toArray().length) {
      const newOtherField =
        values
          .toArray()
          .find(v => v.startsWith(otherFieldPrefix))
          ?.replace(otherFieldPrefix, '') || ''

      const thereIsOtherField = newOtherField.length > 0
      setOtherField(newOtherField)
      setShowInput(thereIsOtherField)
    }
  }, [values])

  useEffect(() => {
    if (Array.isArray(value)) {
      setValues(Set<string>(value))
    }
  }, [value])

  const handleInputChange = (value: string, selected: boolean) => {
    const isOtherField = value === 'other_field'
    let newValues
    if (selected) {
      if (isOtherField) {
        newValues = values.delete('none').delete(prevOtherField)
        const newOtherField = otherFieldPrefix + otherField
        setPrevOtherField(newOtherField)
        newValues = newValues.add(newOtherField)
      } else {
        newValues = value === 'none' ? values.clear() : values.delete('none')
        newValues = newValues.add(value)
      }
    } else {
      if (isOtherField) {
        newValues = values.delete(prevOtherField)
      } else {
        newValues = values.delete(value)
      }
    }
    setValues(newValues)
  }

  const nextHandler = () => {
    onChange(step.id, values.toArray())
  }

  const options = step.options.map((option, optionIdx) => {
    const isOtherField = option.value === 'other_field'
    let additionalProps = {}
    if (isOtherField) {
      additionalProps = { showInput, setShowInput }
    }

    return {
      ...additionalProps,
      value: option.value,
      textField: otherField,
      content: (
        <div className="w-full">
          {option.icon && (
            <Icon
              width="40"
              height="40"
              className="mx-auto"
              name={option.icon}
            />
          )}
          <div className="">
            <p className="mb-2">{option.name}</p>
            {isOtherField && showInput && (
              <input
                type="text"
                className="weight hw-input"
                id="other-field-input"
                min="50"
                max="800"
                placeholder="Type it here"
                value={otherField}
                onChange={e =>
                  setOtherField(e.currentTarget.value.replace(/^\s+/g, ''))
                }
              />
            )}
          </div>
        </div>
      ),
      selected: values.includes(option.value),
      onClick: handleInputChange,
    }
  })

  return (
    <>
      <div className="flex flex-wrap min-w-full max-w-2xl -mx-2 items-center justify-center text-sm md:my-2">
        {step.columns &&
          mapSelectOptions({
            height: 'h-24 sm:h-32',
            width: 'w-1/2 md:w-1/3',
            spacing: 'm-1 sm:mx-3',
            padding: 'p-1 sm:p-3',
            options,
          })}
        {!step.columns &&
          mapSelectOptions({
            height: step.compact ? 'h-15' : 'h-30',
            width: 'w-full',
            spacing: step.compact ? 'm-1' : 'm-2',
            options,
          })}
      </div>
      <Actions
        onNext={nextHandler}
        nextEnabled={nextEnabled}
        isLastStep={props.isLastStep}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
      />
    </>
  )
}
