import {
  convertHeightFromMetricToImperial,
  convertHeightFromImperialToMetric,
} from "@utils/convert-units"
import { useState, useEffect, useRef } from "react";

interface HeightProps {
  value: number | null;
  onChange: (height: number | null) => void
}

const Height: React.FunctionComponent<HeightProps> = ({ value, onChange }) => {
  const { inches, feet } = convertHeightFromMetricToImperial(value)
  const [feetValue, setFeetValue] = useState(feet)
  const [inchesValue, setInchesValue] = useState(inches)
  const feetInput = useRef<HTMLInputElement>(null);
  const inchesInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFeetValue(feet)
    setInchesValue(inches)
  }, [feet, inches, setFeetValue, setInchesValue])

  const feetChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const newValue = parseInt(e.target.value)
    setFeetValue(newValue)
    if (newValue && inchesInput.current) {
      inchesInput.current.focus()
    }
    onChange(convertHeightFromImperialToMetric(newValue, inchesValue))

  }

  const inchesChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const newValue = parseInt(e.target.value)
    setInchesValue(newValue)
    onChange(convertHeightFromImperialToMetric(feetValue, newValue))
  }

  return (
    <div className="flex space-x-10 mb-6 items-center w-full md:w-9/12">
      <div className="flex border-b-4 text-2xl text-input-field bg-transparent w-1/2 justify-between">
        <input required value={feetValue || ""} onChange={feetChangeHandler} type="number" className="height hw-input" min="3" max="8" placeholder="Feet" ref={feetInput} />
        <span>ft.</span>
      </div>
      <div className="flex border-b-4 text-2xl text-input-field bg-transparent w-1/2 justify-between">
        <input required onChange={inchesChangeHandler} value={inchesValue?.toFixed(0) || ""} type="number" className="height hw-input" min="0" max="11" placeholder="Inches" ref={inchesInput} /> <span>in.</span>
      </div>
    </div>
  );
}

export default Height;
