interface MonthProps {
  value: number | null;
  onChange: (value: number) => void
}

const Month: React.FC<MonthProps> = ({ value, onChange }) => {
  const monthNames: Array<string> = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(parseInt(event.target.value));
  }

  return <div>
    <label htmlFor="month" className="sr-only">
      Month
    </label>
    <select
      id="month"
      name="month"
      autoComplete="Month"
      onChange={changeHandler}
      value={value?.toString()}
      className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full bg-transparent focus:z-10 text-lg border-gray-300"
    >
      <option disabled={!!value}>Month</option>
      {monthNames.map((monthName, idx) => <option value={idx + 1} key={idx}>{monthName}</option>)}
    </select>
  </div>
}

export default Month;
